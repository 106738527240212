import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { io } from "socket.io-client";

import {
  useLazyGetPerformerQuery,
  useCreatePaymentLinkMutation,
} from "../services/api";
import { Config } from "../config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const SinglePerformer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const nav = useNavigate();
  const socket = io(Config.API_BASE);

  const [showAlert, setShowAlert] = useState(false);
  const [amount, setAmount] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [showProceedModal, setShowProceedModal] = useState(false);
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [saleUrl, setSaleUrl] = useState("");
  const [showInstallDialog, setShowInstallDialog] = useState(false);

  const [currency, setCurrency] = useState<"USD" | "EUR" | "ILS">("ILS");

  const [getPerformer, { data, isLoading, error }] = useLazyGetPerformerQuery();
  const [createPaymentLink, { isLoading: loadingLink, isError }] =
    useCreatePaymentLinkMutation();

  useEffect(() => {
    socket.on(`payment_${id}`, async (response) => {
      if (response.status === "success") {
        setShowIframe(false);
        setShowInstallDialog(true);
      }
    });
  }, [socket]);

  useEffect(() => {
    getPerformer(id || "__");
  }, [id]);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    }
  }, [error]);

  const onMethodSelected = async (method: string) => {
    setChosenPaymentMethod(method);
    const payload = {
      amount,
      method,
      performerId: id,
      currency,
    };
    const res: any = await createPaymentLink(payload);

    if (res.data && res.data.status_code === 0) {
      setSaleUrl(res.data.sale_url);
      setShowIframe(true);
      setShowProceedModal(false);
      // if (method === "bit") {
      //   window.open(res.data.sale_url, "_blank");
      // }

      return;
    }
  };

  const onSubmit = (charge?: number) => {
    const amountToDonate = charge || amount;
    setAmount(amountToDonate);
    setShowProceedModal(true);
  };

  const currencySymbol = (curr: string) => {
    switch (curr) {
      case "ILS":
        return "₪";

      case "USD":
        return "$";

      case "EUR":
        return "€";

      default:
        return "₪";
    }
  };

  const buttonAmount = (buttonIndex: number) => {
    if (currency === "ILS") {
      switch (buttonIndex) {
        case 1:
          return 5;

        case 2:
          return 10;
        case 3:
          return 20;
        default:
          return 5;
      }
    }

    switch (buttonIndex) {
      case 1:
        return 1;

      case 2:
        return 2;
      case 3:
        return 5;
      default:
        return 1;
    }
  };

  const getButtonLabel = (buttonIndex: number) => {
    return `${currencySymbol(currency)} ${buttonAmount(buttonIndex)}`;
  };

  return (
    <Container>
      <center>
        <img src="/logo.svg" alt="logo" style={{ width: 100, height: 100 }} />

        {isLoading && (
          <Box mt={2}>
            <CircularProgress sx={{ color: "#8fa449" }} />
          </Box>
        )}

        {data && (
          <Box sx={{ width: 300 }}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "#eaeddc",
                border: "none",
                borderRadius: 5,
              }}
            >
              <CardContent>
                <Stack direction="row-reverse">
                  <Box
                    sx={{
                      // alignItems: "flex-end",
                      // textAlign: "end",
                      // float: "right",
                      width: "100px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => {
                        setCurrency(e.target.value as any);
                      }}
                      sx={{
                        height: 20,
                      }}
                    >
                      <MenuItem value={"USD"}>USD</MenuItem>
                      <MenuItem value={"EUR"}>EUR</MenuItem>
                      <MenuItem value={"ILS"}>ILS</MenuItem>
                    </Select>
                  </Box>
                </Stack>

                <Avatar
                  alt={data.name}
                  src={
                    data.image.startsWith("http")
                      ? data.image
                      : Config.BASE_URL + "/" + data.image
                  }
                  sx={{ width: 76, height: 76 }}
                />
                <Box mt={2}>
                  <Typography variant="h5" component="div">
                    {data.name}
                  </Typography>
                </Box>

                <Box mt={1}>
                  <Typography component="p">{data.story}</Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="h6" component="div">
                    Give a tip to: {data.name}
                  </Typography>
                </Box>

                <Box mt={4}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#8fa449",
                        ":hover": {
                          backgroundColor: "#A8B384",
                        },
                      }}
                      onClick={() => {
                        const _amount = buttonAmount(1);
                        setAmount(_amount);
                        onSubmit(_amount);
                      }}
                    >
                      {getButtonLabel(1)}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#8fa449",
                        ":hover": {
                          backgroundColor: "#A8B384",
                        },
                      }}
                      onClick={() => {
                        const _amount = buttonAmount(2);
                        setAmount(_amount);
                        onSubmit(_amount);
                      }}
                    >
                      {getButtonLabel(2)}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#8fa449",
                        ":hover": {
                          backgroundColor: "#A8B384",
                        },
                      }}
                      onClick={() => {
                        const _amount = buttonAmount(3);
                        setAmount(_amount);
                        onSubmit(_amount);
                      }}
                    >
                      {getButtonLabel(3)}
                    </Button>
                  </Stack>

                  <Box mt={2}>
                    {!showInput ? (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: "#8fa449",
                          ":hover": {
                            backgroundColor: "#A8B384",
                          },
                        }}
                        onClick={() => {
                          setShowInput(true);
                        }}
                      >
                        Other Amount
                      </Button>
                    ) : (
                      <Box>
                        <Input
                          type="number"
                          value={amount}
                          onChange={(e) => {
                            if (parseFloat(e.target.value) > 50) {
                              return setAmount(50);
                            }

                            setAmount(parseInt(e.target.value));
                          }}
                          inputProps={{
                            style: {
                              textAlign: "center",
                            },
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setShowInput(false);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  onSubmit(amount);
                                }}
                              >
                                <CheckIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          autoFocus
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        )}
      </center>
      <Dialog
        open={showAlert}
        // onClose={() => setShowAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Performer not found</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There performer was not found or some other error occurred. Please
            try again later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAlert(false);
              nav("/");
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <BootstrapDialog
        // onClose={() => setShowProceedModal(false)}
        aria-labelledby="customized-dialog-title"
        open={showProceedModal}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowProceedModal(false)}
        >
          <center>
            <Box mr={5}>
              Tipping {data?.name} {currencySymbol(currency)}{" "}
              {amount.toFixed(2)}
            </Box>
          </center>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {isError && (
            <Box
              mt={2}
              mb={2}
              sx={{
                width: 300,
              }}
            >
              <Alert severity="error">
                There was an error processing your payment. Please try again
              </Alert>
            </Box>
          )}

          {currency === "ILS" ? (
            <LoadingButton
              sx={{
                marginTop: 2,
                width: "100%",
                backgroundColor: "#8fa449",
                ":hover": {
                  backgroundColor: "#A8B384",
                },
              }}
              size="large"
              onClick={() => {
                if (!loadingLink) onMethodSelected("bit");
              }}
              startIcon={
                <img src="/bit.png" alt="logo" style={{ width: 36 }} />
              }
              loading={loadingLink && chosenPaymentMethod === "bit"}
              loadingPosition="start"
              variant="contained"
            >
              <Typography gutterBottom sx={{ color: "#fff" }}>
                BitPay
              </Typography>
            </LoadingButton>
          ) : null}

          <LoadingButton
            sx={{
              marginTop: 2,
              width: "100%",
              backgroundColor: "#8fa449",
              ":hover": {
                backgroundColor: "#A8B384",
              },
            }}
            size="large"
            onClick={() => {
              if (!loadingLink) onMethodSelected("credit-card");
            }}
            startIcon={
              <img
                src="/cards.png"
                alt="logo"
                style={{ width: 56, objectFit: "contain" }}
              />
            }
            loading={loadingLink && chosenPaymentMethod === "credit-card"}
            loadingPosition="start"
            variant="contained"
          >
            <Typography gutterBottom sx={{ color: "#fff" }}>
              Credit Card
            </Typography>
          </LoadingButton>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        // onClose={() => setShowProceedModal(false)}
        aria-labelledby="customized-dialog-title-2"
        open={showIframe}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title-2"
          onClose={() => setShowIframe(false)}
        >
          {chosenPaymentMethod === "bit"
            ? "Pay with BitPay"
            : "Pay with Credit Card"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box mt={2}>
            <iframe
              src={saleUrl}
              style={{ width: "100%", height: 500 }}
              title="payment-iframe"
            />
          </Box>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        // onClose={() => setShowProceedModal(false)}
        aria-labelledby="customized-dialog-title-3"
        open={showInstallDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title-3"
          onClose={() => {
            setShowInstallDialog(false);
            setShowProceedModal(false);
          }}
        >
          <center>
            <Box>
              <img
                src={Config.LOGO_URL}
                alt="logo"
                style={{
                  width: "90px",
                  height: "100%",
                }}
              />
              <Typography fontWeight={600}>Thank You!</Typography>
            </Box>
          </center>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box mt={2}>
            <ul>
              <li>
                <Typography>Next time - No QR Scans!</Typography>
              </li>

              <li>
                <Typography>Be notified when a performer is nearby</Typography>
              </li>

              <li>
                <Typography>Give tip with a single click</Typography>
              </li>
            </ul>
            <center>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#8fa449",
                  ":hover": {
                    backgroundColor: "#A8B384",
                  },
                  marginTop: 2,
                }}
                href="https://play.google.com/store/apps/details?id=com.tipmeapp.tipme"
                // target="_blank"
              >
                Install Tipme App
              </Button>
            </center>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Container>
  );
};

export default SinglePerformer;

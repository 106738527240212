import { createBrowserRouter } from "react-router-dom";

import Main from "../pages/Main";
import NotFound from "../pages/404";
import RecentPerformers from "../pages/RecentPerformers";
import SinglePerformer from "../pages/SinglePerformer";
import PaySuccess from "../pages/PaySuccess";
import PrivacyPolicy from "../pages/PrivacyPolicy"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <RecentPerformers />,
      },

      {
        path: "/shows",
        element: <RecentPerformers />,
      },
      {
        path: "/shows/:id",
        element: <SinglePerformer />,
      },
      {
        path: "/pay-success",
        element: <PaySuccess />,
      },
      {
        path:"/privacy-policy",
        element: <PrivacyPolicy />
      },

      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;

import { Container } from "@mui/material";
import React from "react";
import { Outlet, Link } from "react-router-dom";

const Main: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        margin: "20px auto",
      }}
    >
      <Outlet />
      <center>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <p>{new Date().getFullYear()} © Leonardo Inventions</p>
      </center>
    </Container>
  );
};

export default Main;

import { Container, Typography } from "@mui/material";
import React from "react";
import { Config } from "../config";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container
      sx={{
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        src={Config.LOGO_URL}
        alt="logo"
        style={{
          width: "90px",
          height: "100%",
        }}
        onClick={() => window.location.replace("/")}
      />

      <Typography variant="h4" component="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <p>
        Tipme uses personal data (email address, name, etc.) to uniquely
        identify users for the payment purposes. We do not sell or share any
        personal identification information (email address, name, phone number,
        etc.) about our users. Users can request their personal data to be
        deleted by submitting a request to support@tipme-app.com. Tipme collects
        location data to enable foreground and background scanning for iBeacons
        even when the app is closed or not in use. In addition, Tipme collects
        precise GPS location of users with “Performer” profile to be able to
        display performers in proximity on a map. There is an option to store
        payment details for a faster tipping in the future. We never store full
        credit card details on our servers. Instead, we only store a public
        token for recurrent payments. If a public token is lost or stolen, it's
        completely useless outside the app.
      </p>
    </Container>
  );
};

export default PrivacyPolicy;

import {
  Box,
  Container,
  Typography,
  Skeleton,
  Pagination,
  Card,
  CardContent,
  Stack,
  Avatar,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useState, useEffect } from "react";
import { Config } from "../config";
import { useLazyGetPerformersQuery } from "../services/api";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const RecentPerformers: React.FC = () => {
  const [getPerformers, { data, isLoading }] = useLazyGetPerformersQuery();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalPages, setTotalPages] = useState(0);

  const [performances, setPerformances] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    getPerformers({ page: 1 });
  }, []);

  useEffect(() => {
    if (data) {
      setPage(data.page);
      setLimit(data.limit);
      setTotalPages(data.totalPages);

      const res = _.uniqBy(data.results, "performer.id");

      setPerformances(res);
    }
  }, [data]);

  return (
    <Container>
      <center>
        <img
          src={Config.LOGO_URL}
          alt="logo"
          style={{
            width: "90px",
            height: "100%",
          }}
        />

        <Box mt={2}>
          <Typography variant="h5" component="h1" gutterBottom>
            Recent Performers
          </Typography>
        </Box>

        <Box mt={2}>
          <Card
            sx={{
              height: "60vh",
              overflowY: "scroll",
              border: "none",
            }}
            variant="outlined"
          >
            <CardContent>
              {(!performances && !isLoading) ||
                (performances && performances.length === 0 && (
                  <Typography variant="h6" component="h2" gutterBottom>
                    No performers found
                  </Typography>
                ))}
              {performances &&
                performances.map((performance: any) => {
                  const { performer } = performance;
                  const imageUrl = performer.image.startsWith("http")
                    ? performer.image
                    : Config.BASE_URL + "/" + performer.image;
                  return (
                    <Stack
                      direction="row"
                      spacing={2}
                      key={performance.id}
                      my={2}
                      sx={{
                        cursor: "pointer",
                        width: "96%",
                        backgroundColor: "#EAEDDC",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => {
                        navigate("/shows/" + performer.id);
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar alt={performer.name} src={imageUrl} />

                        <Typography variant="h6" component="h2" gutterBottom>
                          {performer.name}
                        </Typography>
                      </Stack>

                      <ChevronRightIcon
                        sx={{
                          color: "#8FA449",
                        }}
                      />
                    </Stack>
                  );
                })}

              {!performances && isLoading && (
                <div>
                  <Skeleton variant="rectangular" width="100%" height={58} />
                  <br />
                  <Skeleton variant="rectangular" width="100%" height={58} />
                  <br />
                  <Skeleton variant="rectangular" width="100%" height={58} />
                  <br />
                  <Skeleton variant="rectangular" width="100%" height={58} />
                  <br />
                </div>
              )}
            </CardContent>
          </Card>
        </Box>

        <Box mt={3}>
          <Stack alignItems="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, page) => {
                getPerformers({ page, limit });
              }}
              sx={{
                "& .MuiPaginationItem-root": { color: "#8FA449" },
                "& .Mui-selected": { color: "#8FA449" },
                "& .MuiPaginationItem-root:hover": { color: "#8FA449" },
              }}
            />
          </Stack>
        </Box>

        <Box mt={3}>
          <a
            href="https://play.google.com/store/apps/details?id=com.tipmeapp.tipme"
            target="_blank"
          >
            <img
              src="/logo.svg"
              alt="logo"
              style={{
                width: "50px",
                height: "100%",
              }}
            />
            <br />

            <img
              src="/play.png"
              alt="logo"
              style={{
                width: "100px",
                height: "100%",
              }}
            />
          </a>
        </Box>
      </center>
    </Container>
  );
};

export default RecentPerformers;

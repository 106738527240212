import { Button, Container, Typography } from "@mui/material";
import React from "react";

const NotFound: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <center>
        <Typography variant="h1" component="h2" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          The requested page could not be found.
        </Typography>

        <Button variant="contained" href="/">
          Go Home
        </Button>
      </center>
    </Container>
  );
};

export default NotFound;

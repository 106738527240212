import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";

type PageParams = {
  page?: number;
  limit?: number;
  sortBy?: string;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: Config.API_URL,
  }),
  endpoints: (builder) => ({
    getPerformers: builder.query({
      query: (params: PageParams) => {
        const pp = new URLSearchParams(params as any);

        return `/open/performers?${pp.toString()}`;
      },
    }),

    getPerformer: builder.query({
      query: (id: string) => `/open/performers/${id}`,
    }),

    createPaymentLink: builder.mutation({
      query: (data) => ({
        url: "/open/payments",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetPerformersQuery,
  useLazyGetPerformerQuery,
  useCreatePaymentLinkMutation,
} = api;

import { Box, Container, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";

import { Config } from "../config";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const PaySuccess: React.FC = () => {
  const nav = useNavigate();
  return (
    <Container>
      <BootstrapDialog
        onClose={() => {
          nav("/");
        }}
        aria-labelledby="customized-dialog-title-3"
        open
      >
        <BootstrapDialogTitle
          id="customized-dialog-title-3"
          onClose={() => {
            nav("/");
          }}
        >
          <center>
            <Box>
              <img
                src={Config.LOGO_URL}
                alt="logo"
                style={{
                  width: "90px",
                  height: "100%",
                }}
              />
              <Typography fontWeight={600}>Thank You!</Typography>
            </Box>
          </center>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box mt={2}>
            <ul>
              <li>
                <Typography>Next time - No QR Scans!</Typography>
              </li>

              <li>
                <Typography>Be notified when a performer is nearby</Typography>
              </li>

              <li>
                <Typography>Give tip with a single click</Typography>
              </li>
            </ul>
            <center>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#8fa449",
                  ":hover": {
                    backgroundColor: "#A8B384",
                  },
                  marginTop: 2,
                }}
                href="https://play.google.com/store/apps/details?id=com.tipmeapp.tipme"
                // target="_blank"
              >
                Install Tipme App
              </Button>
            </center>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Container>
  );
};

export default PaySuccess;

import React from "react";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import Router from "./router";
import { api } from "./services/api";

function App() {
  return (
    <ApiProvider api={api}>
      <Router />
    </ApiProvider>
  );
}

export default App;
